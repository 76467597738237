.container {
    margin-top: 30px;
    display: flex;
    
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 10px;
}

.home {
    display: grid;
    grid-template-columns: 1fr .75fr;
    gap: 20px;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin: 20px;
    min-height: 100vh;
    height: auto;
    width: auto;
}

.profile {
    margin-bottom: 13px;
    max-width: 45%;
    max-height: 45%;
    border-radius: 50%;
    overflow: hidden;
  }

  .profileInfo {
    margin-left: 10px;
  }

  .profileName {
    text-align: center;
    width: 42%;
  }

  .author {
    /* display: flex; */
    /* align-items: center; */
    padding-right: 100px;
    margin-bottom: 20px;
  }

.title {
    font-size: 56px;
    /* padding: 0 10px; */
    margin-bottom: 30px;
}

.separator {
    margin: 0px 10px;
    line-height: 2;
    font-size: 25px;
    vertical-align: middle;
}

.blogContainer {
    margin-top: 20px;
    
}

.content {
    margin-bottom: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 15; /* Number of lines to show */
    overflow: hidden;
}

.intro {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 19; /* Number of lines to show */
    overflow: hidden;
    margin-bottom: 20px;
    width: 80%;
}



@media (max-width: 768px) {
    .home {
        padding: 15px;
    }

    .profileContainer {
        margin-bottom: 15px;
    }

    .profileInfo {
        max-width: 100%;
    }

    .blogContainer {
        max-width: 100%;
        padding: 15px;
    }

    .title {
        font-size: 1.5rem;
    }

    .content {
        font-size: 1rem;
    }

    .readMore {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .home {
        padding: 10px;
    }

    .profileContainer {
        margin-bottom: 10px;
    }

    .profile {
        width: 60px;
        height: 60px;
    }

    .profileName {
        font-size: 16px;
    }

    .blogContainer {
        padding: 10px;
    }

    .title {
        font-size: 1.25rem;
    }

    .intro {
        font-size: 0.875rem;
    }

    .content {
        font-size: 0.875rem;
        overflow: scroll;
    }

    .readMore {
        width: 100%;
    }
}