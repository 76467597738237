

.contentContainer {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;

}

.content {
    max-height: 300px;
}

.searchInput {
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
}

.title {
    padding: 20px 20px;
    text-align: center;
}

.buttons {
    display: flex;
    flex-direction: flex-end;
    gap: 10px;
    justify-content: right;
    padding: 15px 5px;
}

.deleteButton,
.updateButton {
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
}

.updateButton {
    background-color: #4CAF50;
    color: white;
}

.deleteButton {
    background-color: red;
    color: white;
}

.noBlog {
    margin-top: 20px;
    text-align: center;
}
