
.container {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-top: 160px;
}

.wrapper {
    background-color: var(--softBg);
    padding: 125px 200px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    border-radius: 10px;
    border: 2px solid #ccc;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

/* .socialButton {
    padding: 20px;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
} */

.register {
    margin-bottom: 50px;
    text-decoration: none;
    transition: color 0.3s; /* Smooth color transition */
    text-align: center;    
    
}

.message {
    margin-top: 50px;
}

.link {
    text-decoration: none;
    transition: color 0.3s; /* Smooth color transition */
  }

.socialButton:first-child {
    background-color: #ff5555;
}

.socialButton:nth-child(2) {
    background-color: #111;
}

.socialButton:nth-child(3) {
    background-color: #087BEA;
}

@media screen and (max-width: 768px) {
    .wrapper {
        padding: 50px 100px;
    }
}

@media screen and (max-width: 640px) {
    .wrapper {
        padding: 30px;
    }

    .socialButton {
        font-size: 14px;
    }
}