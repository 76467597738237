
.blogContainer {
    min-height: 100vh;
    margin: 20px;
    /* margin-top: 60px; */
    border-width: 10px;
    border-color: red;
}

.title {
    margin-top: 20px;
    text-align: center;

}

.author {
    margin-top: 20px;
    text-align: center;
}

.content {
    margin-top: 15px;
    margin-left: 50px;
    margin-right: 50px;
    /* text-align: center; */
    
}