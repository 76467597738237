.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.header {
    margin-bottom: 20px;
}

.section {
    margin-bottom: 20px;
}

.socials {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.socialLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s ease-in-out;
}

.socialLink:hover {
    transform: scale(1.1);
}

.socialIcon {
    height: 40px;
    width: 40px;
}